<template>
  <div>
    <div class="page container">
      <h1>{{ $t("contacts.title") }}</h1>
      <div class="page__descr">
        {{ $t("contacts.descr") }}
      </div>

      <div class="google-maps">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2055.8668251890917!2d76.94840896828704!3d43.221343880357715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38836f1058e4a0d7%3A0x27b50430cd265f50!2z0JDQu9C80LAg0KLQsNGD!5e0!3m2!1sru!2skz!4v1597075523162!5m2!1sru!2skz"
          width="600"
          height="450"
          frameborder="0"
          style="border:0;"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </div>

    <section class="major-wrap">
      <div class="major major--grey">
        <div class="major__inner">
          <div class="container">
            <h3>{{ $t("contacts.requisites") }}</h3>
            <div class="table-wrap">
              <table class="table">
                <tr>
                  <td>{{ $t("contacts.label_name") }}:</td>
                  <td>{{ $t("contacts.name") }}</td>
                </tr>
                <tr>
                  <td>{{ $t("contacts.label_address") }}:</td>
                  <td>{{ $t("contacts.address") }}</td>
                </tr>
                <tr>
                  <td>{{ $t("contacts.label_actual_address") }}:</td>
                  <td>{{ $t("contacts.actual_address") }}</td>
                </tr>
                <tr>
                  <td>{{ $t("contacts.label_phone") }}:</td>
                  <td>
                    +7 727 339 07 72 <br />
                    +7 701 770-88-52
                  </td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>info@rnaenergy.kz</td>
                </tr>
                <tr>
                  <td>{{ $t("contacts.label_bin") }}:</td>
                  <td>200 540 019 486</td>
                </tr>
                <!-- <tr>
                  <td>Текущий счет в банке:</td>
                  <td></td>
                </tr>
                <tr>
                  <td>БИК:</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Полное наименование банка:</td>
                  <td>АО Банк ЦентрКредит</td>
                </tr>
                <tr>
                  <td>Местонахождение банка:</td>
                  <td>
                    050059, Республика Казахстан, город Алматы, Бостандыкский
                    район, проспект Аль-Фараби, 19, БЦ Нурлы-Тау, блок 1b, офис
                    1/2
                  </td>
                </tr> -->
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Contacts"
};
</script>
