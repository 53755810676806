import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Detail from "../views/Detail.vue";
import Contacts from "../views/Contacts.vue";
import E404 from "../views/E404.vue";

import LocaleTemplate from "../views/LocaleTemplate.vue";
import i18n, { loadLocaleMessagesAsync } from "@/i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: i18n.locale
  },
  {
    path: "/:locale",
    component: LocaleTemplate,
    children: [
      {
        path: "",
        name: "Home",
        component: Home
      },
      {
        path: "contacts",
        name: "Contacts",
        component: Contacts
      },
      {
        path: "project",
        name: "Detail",
        component: Detail
      },
      // {
      //   path: "ui",
      //   name: "UiKit",
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () =>
      //     import(/* webpackChunkName: "about" */ "../views/UiKit.vue")
      // },
      {
        path: "*",
        component: E404
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.params.locale === from.params.locale) {
    next();
    return;
  }

  const { locale } = to.params;
  loadLocaleMessagesAsync(locale).then(() => {});

  next();
});

export default router;
