<template>
  <div>
    <section class="page container">
      <h1>{{ $t("project.title") }}</h1>

      <div class="article">
        <div v-html="decoder($t('project.descr'))"></div>

        <p>
          <span class="image-container">
            <img src="@/assets/img/scheme.jpg" alt="" />
          </span>
        </p>

        <h3>{{ $t("project.features[0].title") }}</h3>
        <div v-html="decoder($t('project.features[0].descr'))"></div>

        <h3>{{ $t("project.features[1].title") }}</h3>
        <div v-html="decoder($t('project.features[1].descr'))"></div>

        <p>
          <span class="image-container">
            <img src="@/assets/img/scheme-2.jpg" alt="" />
          </span>
        </p>
        <h3>{{ $t("project.features[2].title") }}</h3>
        <div v-html="decoder($t('project.features[2].descr'))"></div>
      </div>
    </section>
  </div>
</template>

<script>
import decoder from "@/mixins/decoder.js";

export default {
  name: "Detail",
  components: {},
  mixins: [decoder]
};
</script>
