import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import $ from "jquery";
import i18n from "./i18n";

Vue.config.productionTip = false;

window.$ = $;
window.jQuery = $;

require("jquery.scrollex");

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
