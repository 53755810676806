import objectFitImages from "object-fit-images";

export default {
  methods: {
    jq() {
      window.jQuery(document).ready(function($) {
        var $objectfit = $("img.ofi");
        var $objectfitCover = $("img.ofi-cover");
        objectFitImages($objectfit, { watchMQ: true });
        objectFitImages($objectfitCover, { watchMQ: true });
      });
    }
  }
};
