<template>
  <div class="header-menu__locales">
    <router-link
      v-for="locale in locales"
      :key="locale.code"
      :to="{ name: curentRoutName, params: { locale: locale.code } }"
      class="header-menu__locale"
      :class="{ disabled: locale.code === currentLocale.code }"
    >
      <span @click="toggleList">{{ locale.name.toUpperCase() }}</span>
    </router-link>
  </div>
</template>

<script>
import LocaleSwitcherMixin from "@/mixins/locale-switcher";
export default {
  name: "LocaleSwitcherMobile",
  mixins: [LocaleSwitcherMixin]
};
</script>
