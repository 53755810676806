export default {
  methods: {
    //unescape html entities
    decoder(str) {
      var textArea = document.createElement("textarea");
      textArea.innerHTML = str;
      return textArea.value;
    }
  }
};
