<template>
  <div class="container">
    <footer class="footer ">
      ©{{ new Date().getFullYear() }} {{ $t("common.copyright") }}
    </footer>
  </div>
</template>
<script>
export default {
  name: "AppFooter"
};
</script>
