<template>
  <div id="app">
    <app-header />
    <router-view />
    <app-footer />
  </div>
</template>

<script>
import "@/assets/styles/main.sass";

import AppHeader from "@/components/partials/Header";
import AppFooter from "@/components/partials/Footer";

import jq from "@/mixins/jq";

export default {
  name: "App",
  mixins: [jq],
  components: { AppHeader, AppFooter }
};
</script>
