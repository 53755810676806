<template>
  <div>
    <div class="hero">
      <div class="container">
        <div class="hero-logo">
          <img src="@/assets/img/logo-full.png" alt="" />
        </div>
        <h1>RNA Energy</h1>
        <p>{{ $t("home.subtitle") }}</p>
      </div>
    </div>

    <section class="about">
      <div class="container">
        <div v-html="decoder($t('home.descr'))"></div>
        <p class="pt-5">
          <router-link :to="{ name: 'Detail' }" class="btn">
            {{ $t("home.project_link") }}
          </router-link>
        </p>
      </div>
    </section>

    <section class="major">
      <div class="major__inner">
        <div class="container">
          <div
            class="d-flex flex-column flex-sm-row align-items-center major__content"
          >
            <div class="major__text">
              <h2>{{ $t("home.features[0].title") }}</h2>
              <div v-html="decoder($t('home.features[0].descr'))"></div>
            </div>

            <div class="major__img-wrap">
              <div class="major__img major__img--1"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="major major--grey-reverse">
      <div class="major__inner">
        <div class="container">
          <div
            class="d-flex flex-column flex-sm-row align-items-center major__content major__content--reverse"
          >
            <div class="major__img-wrap">
              <div class="major__img major__img--2"></div>
            </div>
            <div class="major__text">
              <h2>{{ $t("home.features[1].title") }}</h2>
              <div v-html="decoder($t('home.features[1].descr'))"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="major">
      <div class="major__inner">
        <div class="container">
          <div
            class="d-flex flex-column flex-sm-row align-items-center major__content"
          >
            <div class="major__text">
              <h2>{{ $t("home.features[2].title") }}</h2>
              <div v-html="decoder($t('home.features[2].descr'))"></div>
            </div>
            <div class="major__img-wrap">
              <div class="major__img "></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import decoder from "@/mixins/decoder.js";

export default {
  name: "Home",
  components: {},
  mixins: [decoder]
};
</script>
