<template>
  <div>
    <header id="header" class="header" :class="{ alt: $route.name === 'Home' }">
      <router-link :to="{ name: 'Home' }" class="header__logo"></router-link>

      <div class="header__burger ml-auto" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </header>
    <div
      class="header__overlay"
      :class="{ open: isMenuOpen }"
      @click="toggleMenu"
    ></div>
    <div class="header-menu" :class="{ open: isMenuOpen }">
      <div class="header-menu__close" @click="toggleMenu"></div>
      <locale-switcher />
      <router-link :to="{ name: 'Home' }" class="header-menu__link">
        {{ $t("menu.home") }}
      </router-link>
      <router-link :to="{ name: 'Detail' }" class="header-menu__link">
        {{ $t("menu.project_1") }}
      </router-link>
      <router-link :to="{ name: 'Contacts' }" class="header-menu__link">
        {{ $t("menu.contacts") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/elements/LocaleSwitcher";

export default {
  name: "AppHeader",
  components: { LocaleSwitcher },
  data() {
    return {
      isMenuOpen: false
    };
  },
  watch: {
    $route(to, from) {
      this.closeMenu();

      setTimeout(() => {
        this.initScrollex();
      }, 200);
    }
  },
  mounted() {
    setTimeout(() => {
      this.initScrollex();
    }, 200);
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    initScrollex() {
      let $window = window.$(window),
        $header = window.$("#header"),
        $banner = window.$(".hero");

      // Header.
      if ($banner.length > 0 && $header.hasClass("alt")) {
        $window.on("resize", function() {
          $window.trigger("scroll");
        });

        $banner.scrollex({
          bottom: $header.outerHeight(),
          terminate: function() {
            $header.removeClass("alt");
          },
          enter: function() {
            $header.addClass("alt");
          },
          leave: function() {
            $header.removeClass("alt");
          }
        });
      }
    }
  }
};
</script>
