export default {
  data() {
    return {
      locales: [
        {
          code: "en",
          name: "eng",
          dir: "ltr"
        },
        {
          code: "ru",
          name: "руc",
          dir: "ltr"
        }
      ],
      isListOpen: false
    };
  },
  computed: {
    currentLocaleCode() {
      return this.$i18n.locale;
      // return this.$route.params.locale;
    },
    curentRoutName() {
      return this.$route.name || "home";
    },
    currentLocale() {
      if (this.currentLocaleCode)
        return this.locales.filter(
          item => item.code === this.currentLocaleCode
        )[0];
      return {};
    },
    displayedLocales() {
      return this.locales.filter(
        locale => locale.code !== this.currentLocaleCode
      );
    }
  },
  watch: {
    isListOpen() {
      window.$(this.$refs.list).slideToggle(200);
    }
  },
  methods: {
    toggleList() {
      this.isListOpen = !this.isListOpen;
    }
  }
};
